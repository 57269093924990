<template>
  <div>
    <PopupLayout width="25rem" @close="$emit('close')">
      <template #title>
        <h2 class="title">{{ $t('bookkeeper.settings.edit', { accountType }) }}</h2>
      </template>
      <template #subtitle>
        <p v-if="type !== ACCOUNT_TYPES.VAT">{{ supplier.name }}</p>
      </template>
      <template #body>
        <el-form ref="form" :model="formModel" :show-message="false">
          <el-form-item prop="accountNumber" class="m-0">
            <el-input v-model="formModel.accountNumber" />
          </el-form-item>
        </el-form>
      </template>
      <template #buttons>
        <div>
          <Button type="secondary" @click="$emit('close')">
            {{ $t('commons.cancel') }}
          </Button>
          <Button type="primary" @click="openConfirmModal">
            {{ $t(formModel.accountNumber ? 'commons.save' : 'commons.remove') }}
          </Button>
        </div>
      </template>
    </PopupLayout>
    <ConfirmSaveDefaultAccount
      v-if="isConfirmModalOpen"
      :type="type"
      :action="!formModel.accountNumber ? 'delete' : null"
      @confirm="confirm"
      @close="isConfirmModalOpen = false"
    />
  </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';

import { Button } from '@/modules/core';

import PopupLayout from './PopupLayout';
import ConfirmSaveDefaultAccount from '../exportWizard/components/ConfirmSaveDefaultAccount';
import { ACCOUNT_TYPES } from '../constants';

export default {
  components: { PopupLayout, Button, ConfirmSaveDefaultAccount },
  props: {
    type: { type: String, required: true },
    account: { type: String, default: null },
    supplier: { type: Object, default: null },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const formModel = ref({ accountNumber: props.account });
    const isConfirmModalOpen = ref(false);

    return {
      ACCOUNT_TYPES,
      formModel,
      accountType: root.$t(`bookkeeping.exportWizard.fields.${props.type}Account`),
      isConfirmModalOpen,
      openConfirmModal: () => {
        root.$refs.form.validate((valid) => {
          if (valid) isConfirmModalOpen.value = true;
        });
      },
      confirm: ({ override }) => {
        formModel.value.accountNumber
          ? emit('confirm', { newAccountNumber: formModel.value.accountNumber, override })
          : emit('delete', { override });
      },
    };
  },
};
</script>

<style scoped lang="scss">
.word-break {
  word-break: normal;
}
</style>
