<template>
  <el-dialog visible custom-class="rounded exclude-confirm-modal" width="400px" @close="$emit('cancel')">
    <template #title>
      <div class="d-flex justify-content-start align-items-center">
        <AlertIcon class="text-danger" />
        <h2 class="title px-2">{{ $t('bookkeeping.excludeConfirmModal.title') }}</h2>
      </div>
    </template>
    <div>
      <span>{{ $t('bookkeeping.excludeConfirmModal.body-part-1') }}</span>
    </div>
    <div>
      <span>{{ $t('bookkeeping.excludeConfirmModal.body-part-2') }}</span>
    </div>

    <template #footer>
      <div class="d-flex justify-content-end align-items-center">
        <Button type="secondary" @click="$emit('cancel')">
          {{ $t('bookkeeping.excludeConfirmModal.cancel') }}
        </Button>
        <Button type="danger" @click="$emit('confirm')">
          {{ $t('bookkeeping.excludeConfirmModal.confirm') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { AlertIcon } from '@/assets/icons';

export default {
  components: { Button, AlertIcon },
};
</script>

<style scoped lang="scss">
:deep(.exclude-confirm-modal) {
  max-height: 225px;
  display: flex;
  flex-direction: column;
  .el-dialog__header {
    padding: 1rem;
  }

  .el-dialog__body {
    padding: 0rem 1rem 1rem 1rem;
  }

  .el-dialog__footer {
    padding: 1rem;
  }

  .title {
    font-size: 1.25rem;
    height: auto;
  }
}
</style>
