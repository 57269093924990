<template>
  <el-dialog
    visible
    :show-close="false"
    custom-class="rounded export-or-validate-modal"
    width="432px"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="title">{{ $t('bookkeeping.exportFile') }}</h2>
      </div>
    </template>
    <div>
      <div>
        <span class="word-break"
          >{{ $t('bookkeeping.numberOfTransactionsNeedsCompletion', { count: numberToValidate, total: totalNumber }) }}
        </span>
      </div>
      <div>
        <span class="word-break">{{ $t('bookkeeping.inOrderToExportOptimally') }}</span>
      </div>
    </div>

    <template #footer>
      <div>
        <Button :loading="loading" type="secondary" @click="$emit('export')">
          {{ validateButtonText }}
        </Button>
        <Button :disabled="loading" @click="$emit('validate')">
          {{ $t('bookkeeping.completingInformation') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';

export default {
  components: { Button },
  props: {
    numberToValidate: { type: Number, required: true },
    totalNumber: { type: Number, required: true },
    loading: { type: Boolean, default: false },
  },
  computed: {
    validateButtonText() {
      return this.numberToValidate === this.totalNumber
        ? this.$t('commons.cancel')
        : this.$t('bookkeeping.exportWithoutCompletingInformation');
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.export-or-validate-modal) {
  .el-dialog__header {
    padding: 1rem;
  }

  .el-dialog__body {
    padding: 0rem 1rem 1rem 1rem;
  }

  .el-dialog__footer {
    padding: 1rem;
  }

  .title {
    font-size: 1.25rem;
  }

  .word-break {
    word-break: break-word;
  }
}
</style>
